import React from 'react';
import cx from 'classnames';
import Styles from '../assets/styles/askBrokerForm.module.css';


const MessageVerification = props => {

    return (
        <div className={cx(Styles.messageverifction, 'col-12')}>
        <div className={'row'}>

                    <div className={cx(Styles.messageVerifyContnt, 'col-xl-8 col-lg-9 col-sm-12 col-12 mr-auto ml-auto ')}>

                        <h2 className={Styles.heading}>Message Pending. Please verify your email</h2>
                        <div className={Styles.msgdescription}>
                            <p>Message Sent! <br />Before our brokers can see your message,you need to verify email. </p>
                            <p>please check your email(and SPAM folder) and follow the instruction</p>
                            <p>We do this to avoid people to scam to our community.</p>
                        </div>
                    </div>



                </div>
            </div>
       

    );
};

export default MessageVerification;