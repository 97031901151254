import React from 'react'
import AskBroker from '../components/askBroker'
import MessageVerification from '../components/messageVerification'
// import Layout from '../components/layout'
// import bootstrapStyles from "../assets/styles/bootstrap-grid.module.css"
import Styles from '../assets/styles/askBrokerForm.module.css';

const QuestionPost = () => (
    <section className={Styles.askbroker}>
        <div className={'container'}>
            <div className={'row'}>
                <AskBroker />
                <MessageVerification />
            </div>
        </div>
    </section>
)

export default QuestionPost