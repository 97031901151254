import React from 'react';
import cx from 'classnames';
import Styles from '../assets/styles/askBrokerForm.module.css';


const AskBroker = props => {

    return (

        <div className={cx(Styles.askbrokerForm, 'col-12')}>
            <div className={'row'}>

                <div className={cx(Styles.askbrokerFormHeadings, 'col-xl-8 col-lg-9 col-sm-12 col-12 mr-auto ml-auto')}>

                    <h2 className={cx('text-center', Styles.heading)}>Ask our experts anything about home loan without sharing your details <nobr>with them.</nobr></h2>
                    <p className={cx('text-center', Styles.subheading)}>You'll be notified instantly by email once a broker from your postcode area responds to your question, generally within few hours or even minutes.</p>
                </div>
                <div className={cx(Styles.askbrokerFormfields, 'col-xl-5 col-lg-6 col-sm-10 col-12 mr-auto ml-auto')}>

                    <div className={cx(Styles.formGroup, 'form-group')}>
                        <label>Your first name</label>
                        <input type="text" className={cx(Styles.formControl, 'form-control')} placeholder="" />
                    </div>

                    <div className={cx(Styles.formGroup, 'form-group')}>
                        <label>Your email(we will ask you to verify it)</label>
                        <input type="text" className={cx(Styles.formControl, 'form-control')} placeholder="Enter a valid email" />
                    </div>

                    <div className={cx(Styles.formGroup, 'form-group')}>
                        <label>Your Postcode</label>
                        <input type="text" className={cx(Styles.formControl, 'form-control')} placeholder="" />
                    </div>

                    <div className={cx(Styles.formGroup, 'form-group')}>
                        <label>Your Message</label>
                        <textarea className={cx(Styles.formControl, Styles.msgarea, 'form-control')} rows="3"></textarea>
                    </div>

                    <div className={cx(Styles.formGroup, Styles.fieldsubmtBtn, 'form-group')}>
                        <a className={cx(Styles.btn, 'blue-btn btn')} href="javascript://">Submit Message</a>
                    </div>

                </div>



            </div>

        </div>

    );
};

export default AskBroker;